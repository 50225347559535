import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tooltip, Position, Button, TooltipColor } from '@class101/ui';
import { Playground, Props } from 'docz';
import { Component } from './Tooltip';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <p>{`The component that appears when the mouse hovers over it.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Component} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`<Tooltip content="This is tooltip">
  <Button>Mouse hover</Button>
</Tooltip>
`}</code></pre>
    <h2 {...{
      "id": "with-position"
    }}>{`with Position`}</h2>
    <Tooltip content="This is tooltip" position={Position.LEFT} mdxType="Tooltip">
  <Tooltip content="This is tooltip" position={Position.TOP} mdxType="Tooltip">
    <Tooltip content="This is tooltip" position={Position.RIGHT} mdxType="Tooltip">
      <Tooltip content="This is tooltip" position={Position.BOTTOM} contentTitle="This tooltip has title" mdxType="Tooltip">
        <Button mdxType="Button">Mouse hover</Button>
      </Tooltip>
    </Tooltip>
  </Tooltip>
    </Tooltip>
    <h2 {...{
      "id": "with-color"
    }}>{`with Color`}</h2>
    <Playground __position={2} __code={'<Tooltip\n  content=\"This is tooltip\"\n  position={Position.RIGHT}\n  color={TooltipColor.ORANGE}\n>\n  <Button>Mouse hover</Button>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      Tooltip,
      Position,
      Button,
      TooltipColor,
      Playground,
      Props,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content="This is tooltip" position={Position.RIGHT} color={TooltipColor.ORANGE} mdxType="Tooltip">
    <Button mdxType="Button">Mouse hover</Button>
  </Tooltip>
    </Playground>
    <h2 {...{
      "id": "with-state"
    }}>{`with State`}</h2>
    <Playground __position={3} __code={'<Tooltip\n  content=\"This is tooltip\"\n  position={Position.RIGHT}\n  color={TooltipColor.ORANGE}\n  isOpen={true}\n>\n  <Button>Mouse hover</Button>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      Tooltip,
      Position,
      Button,
      TooltipColor,
      Playground,
      Props,
      Component,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tooltip content="This is tooltip" position={Position.RIGHT} color={TooltipColor.ORANGE} isOpen={true} mdxType="Tooltip">
    <Button mdxType="Button">Mouse hover</Button>
  </Tooltip>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      